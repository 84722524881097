import { useWallet } from "@sei-js/react";
import Axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { useCallback } from "react";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const cache = setupCache({
  maxAge: 2500,
  clearOnStale: true,
  clearOnError: true,
  readHeaders: true,
  exclude: {
    query: false,
    methods: ["post", "patch", "put", "delete"],
  },
});

const axios = Axios.create({
  adapter: cache.adapter,
});

const useClient = () => {
  const { connectedWallet, accounts } = useWallet();

  const queryContract = async (contractAddress, queryMsg) => {
    try {
      const url = `${
        process.env.REACT_APP_REST_URL
      }/cosmwasm/wasm/v1/contract/${contractAddress}/smart/${btoa(queryMsg)}`;
      const res = (await axios.get(url)).data;
      return res.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  const getAcquisitionTime = async (tokenId, walletAddress) => {
    try {
      // Hypothetical endpoint or method to get the transfer event of the NFT to the current owner
      const transferEventUrl = `${process.env.REACT_APP_API_URL}/getNftTransferEvent?tokenId=${tokenId}&owner=${walletAddress}`;
      
      const response = await axios.get(transferEventUrl);
      const data = response.data;
      
      // Assuming the API returns the timestamp of when the NFT was transferred to the current owner
      const acquisitionTime = data.transferTimestamp;
      
      return acquisitionTime;
    } catch (error) {
      console.error('Error fetching acquisition time:', error);
      return null; // Handle the error appropriately
    }
  };

  const queryContractMyNfts = useCallback(
    async (walletAddress) => {
      const queryMsg = `{
        "tokens": {
          "owner": "${walletAddress}",
          "limit": 500
        }
      }`;

      let response = await queryContract(CONTRACT_ADDRESS, queryMsg);
      return response;
    },
    [connectedWallet, accounts]
  );

  const queryNftInfo = useCallback(
    async (tokenId) => {
      const queryMsg = JSON.stringify({
        nft_info: {
          token_id: tokenId,
        },
      });

      try {
        // Fetch basic NFT info from your contract
        let nftBasicInfo = await queryContract(CONTRACT_ADDRESS, queryMsg);
        if (nftBasicInfo && nftBasicInfo.token_uri) {
          // Fetch detailed metadata from the token_uri
          const response = await axios.get(nftBasicInfo.token_uri);
          const detailedMetadata = response.data;

          // Combine basic info and detailed metadata
          return { ...nftBasicInfo.extension, ...detailedMetadata };
        }
        return null;
      } catch (error) {
        console.error('Error fetching NFT info:', error);
        return null;
      }
    },
    [queryContract]
  );

  return {
  queryContractMyNfts,
  queryNftInfo, // Add this line
  getAcquisitionTime,
};
};

export default useClient;
