import "./App.css";

import { Spinner } from "@material-tailwind/react";
import { EventBus } from "./utils/methods";
import { SET_LOADING} from "./utils/constants";
import { useEffect, useState } from "react";
import NFTItem from "./pages/NFTItem";
import WalletConnectButton from "./components/buttons/WalletConnectButton";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  
  const setLoading = (data) => {
    setIsLoading(data);
  };

  useEffect(() => {
    EventBus.on(SET_LOADING, (data) => {
      setLoading(data);
    });

    return () => {
      EventBus.remove(SET_LOADING);
    };
  }, []);


  return (

    <div
      className="App flex flex-col min-h-[100vh] overflow-x-hidden text-eloblack items-center"
      
    >
      <div className="flex w-[100vw] lg:w-[1200px] justify-between h-max px-6 md:px-10 py-5 md:py-8 items-start ">
        <div className="w-full relative">
          <header className="flex flex-col sm:flex-row justify-between items-center w-full">
            <div className="flex flex-col items-center sm:flex-row sm:items-center w-full text-center sm:text-left">
              
            </div>

            <div className="mt-4 sm:mt-0 sm:ml-4">
              <WalletConnectButton className="ml-[22px] sm:ml-0" />
            </div>
          </header>
          <div className="w-full py-[30px]">
            <NFTItem />
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 999,
          top: 0,
          left: 0,
          display: `${isLoading ? "flex" : "none"}`,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner color="blue" className="h-10 w-10" />
      </div>
    </div>
  );
}

export default App;
