import { useEffect, useState } from "react";
import { useWallet } from "@sei-js/react";
import useClient from "../utils/client";

export default function NFTItem({ className }) {
    const [nftIds, setNftIds] = useState([]);
    const { queryContractMyNfts, queryNftInfo } = useClient();
    const { connectedWallet, accounts } = useWallet();
    const [walletAddress, setWalletAddress] = useState('');

    useEffect(() => {
        // Assuming the first account is the primary one
        if (accounts && accounts.length > 0) {
            setWalletAddress(accounts[0].address);
        }
    }, [accounts]);

    const fetch = async () => {
        if (connectedWallet) {
            const tokens = await queryContractMyNfts(accounts[0].address);

            if (tokens && tokens.tokens) {
                const metadataPromises = tokens.tokens.map(tokenId =>
                    queryNftInfo(tokenId)
                );
                const metadata = await Promise.all(metadataPromises);

                const ids = metadata.map(item => {
                    const match = item.name.match(/#(\d+)/);
                    return match ? match[1] : null;
                }).filter(id => id !== null);

                setNftIds(ids);
            }
        }
    };

    useEffect(() => {
        fetch();
    }, [connectedWallet, queryContractMyNfts, accounts]); // Include accounts in the dependency array

    // Update the iframeSrc to include the wallet address
    const iframeSrc = `https://ymektvsw2zajbl.cryptoguardalliance.com/balls/?nfts=${encodeURIComponent(nftIds.join(','))}&wallet=${encodeURIComponent(walletAddress)}`;

    return (
        <div className={`${className} flex flex-col`}>
            <div className="w-full flex flex-row justify-center">
                <div className="overflow-hidden w-full flex flex-col justify-between mainjank">
                    {nftIds.length > 0 && walletAddress ? ( // Ensure walletAddress is not empty
                        <iframe src={iframeSrc} width="100%" height="4000" style={{ border: "none" }}></iframe>
                    ) : (
                        <div className="text-center p-4">
                            <p className="nocga">Sorry, You must have balls to enter!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
